<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="환경/안전보건 경영검토 보고서 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-show="editable && popupParam.reviewReportId" label="삭제" icon="delete_forever" @btnClicked="removeData" />
                <c-btn
                  v-show="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-plant
                  required
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  required
                  :editable="editable"
                  label="제목"
                  name="reportTitle"
                  v-model="data.reportTitle">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  required
                  :editable="editable"
                  label="작성일"
                  name="reportDate"
                  v-model="data.reportDate">
                </c-datepicker>
              </div>
              <div class="col-12">
                <c-textarea
                  :editable="editable"
                  type="editor"
                  label="보고서 내용"
                  name="reportContents"
                  maxHeight="600px"
                  :editheight="40"
                  v-model="data.reportContents">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="첨부파일" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-sm-12 col-md-12 col-lg-12">
                <c-upload 
                  :attachInfo="attachInfo"
                  :editable="editable">
                </c-upload>
              </div>
            </template>
          </c-card>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'iso-review-report-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        reviewReportId: '',
      }),
    },
  },
  data() {
    return {
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'ISO_REVIEW_REPORT',
        taskKey: '',
      },
      saveUrl: '',
      mappingType: 'POST',
      regUserName: '',
      deleteUrl: '',
      editable: true,
      isSave: false,
      data: {
        reviewReportId: '',
        plantCd: '',
        reportDate: '',
        reportContents: '',
        reportTitle: '',
        regUserId: '',
      },
      updateMode: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.iso.report.get.url;
      this.saveUrl = transactionConfig.sai.iso.report.insert.url;
      this.deleteUrl = transactionConfig.sai.iso.report.delete.url;
      // code setting
      this.data.reportDate = this.$comm.getToday();      
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.reviewReportId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {reviewReportId: this.popupParam.reviewReportId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo',this.data)

          this.$set(this.attachInfo, 'taskKey', this.popupParam.reviewReportId)
        },);
      }
    },
    saveData() {
      if (this.popupParam.reviewReportId) {
        this.saveUrl = transactionConfig.sai.iso.report.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sai.iso.report.insert.url;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',  
        message: '삭제하시겠습니까?', 
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.reviewReportId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.popupParam.reviewReportId) {
        this.$set(this.attachInfo, 'taskKey', this.popupParam.reviewReportId)
        this.$set(this.attachInfo, 'isSubmit', uid())
      }
      this.popupParam.reviewReportId = result.data.reviewReportId;
      this.getDetail();
    },
  }
};
</script>
